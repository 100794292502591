"use client";

import { Home, Moon, Sun } from "lucide-react";
import { useTheme } from "next-themes";
import * as React from "react";

import { Button } from "@/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { NavigationMenu, NavigationMenuItem, NavigationMenuLink, NavigationMenuList, navigationMenuTriggerStyle } from "@/components/ui/navigation-menu";
import Image from "next/image";
import Link from "next/link";
import { darkenHexColor } from "@/lib/helperFunctions";
import { useStylesStore } from "@/lib/stores/styles_store";

const routes = [
  {
    path: "/",
    name: "Home",
    icon: Home,
  },
];

export const NavItem = ({ icon, name, path }) => {
  const globalStyles = useStylesStore((state) => state.globalStyles);
  const [collectionHovered, setCollectionHovered] = React.useState(null);

  const Icon = icon;
  return (
    <Link href={path} className="nav-button" style={{ backgroundColor: collectionHovered ? darkenHexColor(globalStyles.sidebar_bg, 8) : null }} onMouseEnter={() => setCollectionHovered(true)} onMouseLeave={() => setCollectionHovered(false)}>
      <Icon className="nav-button-icon" color="black" />
      {name}
    </Link>
  );
};

export const NavSidebar = () => {
  return (
    <div className="flex flex-col space-y-2">
      {/* {routes.map((route, i) => {
        return <NavItem icon={Home} name="Home" />;
      })} */}
    </div>
  );
};

export default async function Nav() {
  const { setTheme } = useTheme();
  //Put logic here to account for logged in users who already have something stored. Clear if a user is signed out though

  return (
    <NavigationMenu>
      <NavigationMenuList className="h-[48px] justify-center">
        <NavigationMenuItem>
          <Link href="/" legacyBehavior passHref>
            <NavigationMenuLink className={`${navigationMenuTriggerStyle()} gap-2`}>
              <Image src="/logo.png" width={100} height={60} />
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <Link href="/" legacyBehavior passHref>
            <NavigationMenuLink className=" gap-2">
              <Home />
              Home
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>

        <NavigationMenuItem>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" size="icon">
                <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
                <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
                <span className="sr-only">Toggle theme</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={() => setTheme("light")}>Light</DropdownMenuItem>
              <DropdownMenuItem onClick={() => setTheme("dark")}>Dark</DropdownMenuItem>
              <DropdownMenuItem onClick={() => setTheme("system")}>System</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
}
